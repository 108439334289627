import React from "react";
import { useLangContext } from "../../i18n/ProvideLang";
import Utils from "../../services/utils";
import img1 from "../../images/placeholder.png"

const ProductCard = ({item, ...props}) => {
    const {localizationsItems, lang} = useLangContext();
  return (
      <div className="item-inner" {...props}>
        <div className="prd">
          <div className="item-img clearfix">
            <div className="ico-label">
              {item.is_sale && <span className="ico-product ico-sale">Sale</span>}
              {item.is_new && <span className="ico-product ico-new">New</span>}
            </div>
            <div className="product-image have-additional">
                {item.detail_image ?
                    <span className="img-main">
                        <img className="fisrt-img" alt="" src={item.preview_image ? item.preview_image : img1} 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img1
                                }}
                        /> 
                        <img className="second-img" src={item.detail_image ? item.detail_image : img1} 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img1
                                }}
                                alt=""
                        />
                    </span>
                :
                    <span className="img-main">
                        <img src={item.preview_image ? item.preview_image : img1} 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img1
                                }}
                        /> 
                    </span>
                }
                <div className="action-bot">
                    <div className="wrap-addtocart">
                    <button className="btn-cart" title="Add to Cart">
                        <i className="fa fa-shopping-cart"></i>
                        <span>{localizationsItems?.to_cart}</span>
                    </button>
                    </div>
                </div>
            </div>
          </div>
          <div className="item-info">
            <div className="info-inner">
              <div className="item-title">
                <div title="Modular Modern">
                  {item.title}
                </div>
              </div>
              <div className="item-price">
                <div className="price-box">
                  <span className="regular-price">
                    <span className="price">
                        <span className="price1">{item.subtitle ? item.subtitle : 
                            <>
                                {item.price ?
                                    <>
                                        {Utils.numberWithSpaces(item.price)} {localizationsItems?.currency}
                                    </>
                                    :
                                    localizationsItems?.not_specified
                                }
                            </>}
                        </span>
                        {item.old_price && <span className="price2"> {Utils.numberWithSpaces(item.old_price)} {localizationsItems?.currency}</span>}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ProductCard;
