import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import Modal from 'react-bootstrap/Modal';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import img1 from "../../images/placeholder.png"

const Slider = ({gallery}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [image, setImage] = useState();
    const [show, setShow] = useState();

    const handleClose = () => setShow(false);

    const openModal = (e, elem) => {
        e.stopPropagation();
        setImage(elem);
        setShow(true)
    }

  return (
    <>
        <div className="product-img-box col-md-4 col-sm-5 mb-5">
            <Swiper
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
            >
                {gallery?.map((elem, index) => 
                    <SwiperSlide key={index} className='product-slide' onClick={(e) => openModal(e, elem)}>
                        <img alt="" src={elem ? elem : img1} 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img1
                                }}
                        /> 
                    </SwiperSlide>
                )}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
            >
                {gallery?.map((elem, index) => 
                    <SwiperSlide key={index} className='product-slide2'>
                        <img alt="" src={elem ? elem : img1} 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img1
                                }}
                        /> 
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
        <Modal show={show} onHide={handleClose} centered className='modal-new'>
            <Modal.Body>
                <span className='fa fa-close' onClick={handleClose}></span>
                <img alt="" src={image ? image : img1} 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img1
                                }}
                        /> 
            </Modal.Body>
        </Modal>           
    </>
  )
}

export default Slider